@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-width: 100%;
  max-width: 100%;
}

html {
  display: flex;
  min-height: 100%;
  max-height: 100%;
}

body,
#root {
  flex-grow: 1;
  display: flex;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
